import React from 'react';
import { FaCaretDown } from 'react-icons/fa';
import arrow_icon from '../../Assets/breadcrum_arrow.png';

const BreadcrumbHeader = ({ isMobile, handleMobileMenuToggle, activeTab }) => {
    const handleMobileMenu = () => {
        handleMobileMenuToggle(true);
        console.log(true);
    };

    return (
        <div className="px-4 py-2 flex flex-row text-sm md:text-base">
            <div className='flex-1'>
                <nav className=" text-gray-700">
                    <ol className="list-reset flex items-center font-sans">
                        <li><a href="#" className="font-sans">企业管理系统</a></li>
                        <li>
                            <span className="mx-2">
                                <img src={arrow_icon} alt="arrow_icon" className="w-2 h-2 mx-2" />
                            </span>
                        </li>
                        <li className='text-darkyellow'>
                            {activeTab === 'account' ? '账户信息' :
                                activeTab === 'messages' ? '消息列表' :
                                    activeTab === 'eventList' ? '活动列表' :
                                        activeTab === 'postEvent' ? '发布活动' :
                                            activeTab === 'editEvent' ? '编辑活动' :
                                                activeTab === 'editHomepage' ? '主页修改' :
                                                    activeTab === 'shopmall' ? '积分商城' :
                                                        '无效选项'}
                        </li>
                    </ol>
                </nav>
            </div>
            {isMobile && (
                <div className='flex flex-row items-center font-sans'>
                    <div className='text-gray-700 font-sans'>功能切换</div>
                    <div className="ml-2">
                        <FaCaretDown
                            className="cursor-pointer"
                            onClick={handleMobileMenuToggle}
                        />
                    </div>
                </div>
            )}
        </div>
    )
};

export default BreadcrumbHeader;
