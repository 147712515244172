import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import companyReducer from './Components/Companies/companiesSlice';
import questionReducer from './Components/QA/questionSlice';
import newsReducer from './Components/News/newsSlice';
import authReducer from './Components/Auth/authSlice';
import commentsReducer from './Components/Comment/commentsSlice';
import languageReducer from './Components/Common/LanguageSlice'; 

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['companies', 'questions', 'news', 'auth', 'language'], 
};

const rootReducer = combineReducers({
  companies: companyReducer,
  questions: questionReducer,
  news: newsReducer,
  auth: authReducer,
  comments: commentsReducer,
  language: languageReducer, 
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);