import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'en', // 默认语言
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
        console.log('Setting language to:', action.payload); // 确认 action 是否正确

      state.language = action.payload;
      // 清除缓存的公司数据
      state.cachedCompanies = false;
      state.cachedLandingPageCompanies = false;
      state.cachedCompany = false;
      state.cachedAdvertisements = false;
    },
  }
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
