import React from 'react';
import FormattedText from '../Common/FormattedText';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageData } from '../Common/LanguageData';
import news_left_icon from '../../Assets/CompanyPageIcons/company_left.png';
import news_right_icon from '../../Assets/CompanyPageIcons/company_right.png';

const CompanyInfo = ({ company }) => {
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const SingleCompanyPageData = LanguageData[language].SingleCompanyPage; 

  return (
    // <div className="relative w-80% mx-[50px] md:mx-[50px] bg-white border border-gray-200 shadow-lg rounded-lg p-3 md:p-8 pt-12 z-10 -mt-[120px] mb-10">
    <div className="relative w-80% mx-6 sm:mx-24 md:mx-auto md:w-[720px] lg:w-[1000px] bg-white border border-gray-200 shadow-lg p-3">
      {/* 上面部分 Logo */}
      {/* <div className="flex justify-center items-center z-20">
        <div className={`w-16 h-16 md:w-32 md:h-32 flex justify-center `}>
          <img
            src={company.image}  
            alt="Company Logo"
            className="w-full h-full object-cover rounded-full shadow-lg"
          />
        </div>
      </div> */}

      <div className="flex justify-center flex-col items-center mt-6 md:flex-row gap-x-4">
        {/* 公司名称 */}
        <div className="text-center mt-4 flex items-center justify-center">
          <img 
            src={news_left_icon} 
            alt="Left Icon" 
            className="h-5 w-9" 
          />
          <h1 className="text-lg md:text-xl mx-2 font-bold text-gray-800">
            {company.name}
          </h1>
          <img 
            src={news_right_icon} 
            alt="Right Icon" 
            className="h-5 w-9" 
          />
        </div>


      </div>
      
      {/* 主营业务部分 */}
      <div className="flex flex-col items-center gap-4 mt-4 mb-6">
        {/* 地区和主营业务 */}
        <div className="flex justify-center items-center">
          {/* 地区 */}
          <div className="text-gray-800 text-sm md:text-base px-3 pt-1">
            {SingleCompanyPageData.location + company.location}
          </div>

          {/* 主营业务 */}
          <div className="text-gray-800 text-sm md:text-base px-3 pt-1">
            {/* {SingleCompanyPageData.business + company.industry} */}
            {SingleCompanyPageData.business + company.industry.charAt(0).toUpperCase() + company.industry.slice(1)}
          </div>
        </div>
        {/* 分割线 */}
        <div className="w-1/3 border border-yellow -mt-3"></div> 
      </div>

      {/* 公司简介部分 */}
      <div className="text-left mb-6">
        <h2 className="text-md md:text-xl font-bold text-gray-800 mb-4">{SingleCompanyPageData.companyIntro}</h2>
        <div className="text-sm md:text-base text-gray-600">
          <FormattedText text={company.detail} />
        </div>
      </div>

      {/* 产品展示部分 */}
      {company.product && (<div className="text-left">
        <h2 className="text-md md:text-xl font-bold text-gray-800 mb-2">{SingleCompanyPageData.products}</h2>
        <p className="text-base md:text-md text-gray-600">
          {SingleCompanyPageData.productsContent}        
        </p>
      </div>)}
    </div>
  );
};

export default CompanyInfo;