import { IoMdAlarm } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { fetchEventData } from "../../API/api";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import LoadingSpinnerInBox from "../Common/LoadingSpinnerInBox";
const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

export default forwardRef(function ({ language }, ref) {

  const loading = LoadingSpinnerInBox
  const [content, setContent] = useState(loading)
  const [displayClassName, setDisplayClassName] = useState('hidden')
  // if (!event) return <></>

  const getCurrentLanguageKey = (key) => language === 'en' ? `${key}_en` : key

  function renderEventDetail(eventData) {
    const title = eventData[getCurrentLanguageKey('Event')]
    const host = eventData[getCurrentLanguageKey('Host')]
    const address = eventData[getCurrentLanguageKey('Address')]
    const startDate = eventData['Start_date']
    const endDate = eventData['End_date']
    const startTime = eventData['Start_time']
    const endTime = eventData['End_time']
    const image = eventData.Image
    return (<>
      <img className="w-1/3 object-scale-down" src={image} alt="Loading" />
      <div className="h-full flex-1 flex flex-col items-center justify-around">
        <h2 className="text-lg md:text-lg text-black font-semibold">{title}</h2>
        <p>{host}</p>
        <p>{address}</p>
        <p>{`${startTime} - ${endTime}`}</p>
        <p>{`${startDate} - ${endDate}`}</p>
      </div>
    </>)
  }
  function display(eventId) {
    setDisplayClassName('flex')
    fetchEventData(eventId).then((eventData) => {
      setContent(renderEventDetail(eventData))
    })


  }

  function close() {
    setDisplayClassName('hidden')
    setContent(loading)
  }


  useImperativeHandle(ref, () => ({
    display, close
  }))


  return (

    <div className={`
      ${displayClassName}
      pointer-events-none
      border-black border-4 shadow-xl shadow-black
     fixed inset-0 m-auto lg:w-2/3 w-2/3 h-1/3 z-50 justify-between bg-white rounded-lg p-4
     `}>
      {content}
    </div>)
})