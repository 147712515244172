import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const FeatureBox = ({ title, to, Icon }) => {
  const currentLanguage = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const getLocalizedPath = (path, language) => {
    return language === 'cn' ? `/cn${path}` : path;
  };

  return (
    <div className={`transform transition-all duration-500 ${
      isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
    }`}>
      <Link 
        // to={to} 
        to={getLocalizedPath(to, currentLanguage)}
        className="block group"
      >
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 text-yellow">
          <div className="flex flex-col items-center space-y-4">
            <div className="transform group-hover:scale-110 transition-transform duration-300">
              <Icon className="w-12 h-12 md:w-16 md:h-16" />
            </div>
            <h2 className="h-12 items-center sm:h-6 text-center text-sm md:text-base lg:text-lg font-semibold text-gray-800 group-hover:text-yellow-500 transition-colors duration-300">
              {title}
            </h2>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default FeatureBox;