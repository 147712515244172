import { IoBookOutline } from "react-icons/io5";
import React from "react";

const Law = () => {
    return (
      <div>
        <IoBookOutline className="w-16 h-16" /> 
      </div>
    );
  };
  
  export default Law;