import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../Auth/authSlice';
import logo from '../../Assets/logo.png';
import searchIcon from '../../Assets/NavbarIcons/search-icon.png'
import userIcon from '../../Assets/NavbarIcons/user-icon.png'
import helpIcon from '../../Assets/NavbarIcons/help-icon.png'
import { FaSearch, FaBars, FaArrowRight, FaUserCircle } from 'react-icons/fa';
import { MdHelpOutline } from 'react-icons/md';
import SearchResults from './SearchResults';
import LoginComponent from '../Auth/LoginComponent';
import { setLanguage } from '../Common/LanguageSlice';
import { LanguageData } from '../Common/LanguageData';

const Navbar = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // 登录状态

  const [showSearch, setShowSearch] = useState(false);
  const [showIndustryDropdown, setShowIndustryDropdown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showMobileIndustryDropdown, setShowMobileIndustryDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]); // 用于存储搜索结果
  const [notFound, setNotFound] = useState(false); // 是否搜索到相关公司
  const [searchPerformed, setSearchPerformed] = useState(false); // 是否已经搜索过
  const [showUserDropdown, setShowUserDropdown] = useState(false); // 控制用户下拉菜单显示
  const [showLogin, setShowLogin] = useState(false); // 控制登录组件显示

  const searchRef = useRef();
  const searchInputRef = useRef();
  const industryTextRef = useRef();
  const industryDropdownRef = useRef();
  const dropdownTimeout = useRef(null);
  const showDropdownTimer = useRef(null);
  const loginComponentRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const getLocalizedUrl = (baseUrl, currentLanguage, endpoint) => {
    return currentLanguage === 'cn' ? `${baseUrl}/cn/${endpoint}` : `${baseUrl}/${endpoint}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearch(false);
        setSearchResults([]);
      }
      if (!industryTextRef.current?.contains(event.target) && !industryDropdownRef.current?.contains(event.target)) {
        handleCloseDropdown();
      }
      if (showLogin && loginComponentRef.current && !loginComponentRef.current.contains(event.target)) {
        setShowLogin(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleUserIconClick = () => {
    if (isLoggedIn) {
      setShowUserDropdown(!showUserDropdown);
    } else {
      setShowLogin(true);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    dispatch(logout());
    setShowUserDropdown(false);
    navigate('/');
  };

  const handleIndustryTextEnter = () => {
    clearTimeout(dropdownTimeout.current);
    showDropdownTimer.current = setTimeout(() => {
      setShowIndustryDropdown(true);
    }, 300);
  };

  const handleIndustryTextLeave = () => {
    clearTimeout(showDropdownTimer.current);
    handleCloseDropdown();
  };

  const handleDropdownEnter = () => {
    clearTimeout(dropdownTimeout.current);
  };

  const handleDropdownLeave = () => {
    handleCloseDropdown();
  };

  const handleCloseDropdown = () => {
    clearTimeout(showDropdownTimer.current);
    dropdownTimeout.current = setTimeout(() => {
      setShowIndustryDropdown(false);
    }, 200);
  };

  const handleMobileMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
    setShowMobileIndustryDropdown(false);
  };

  const handleMobileIndustryClick = () => {
    setShowMobileIndustryDropdown(!showMobileIndustryDropdown);
  };

  const handleMobileIndustryItemClick = (path) => {
    navigate(getLocalizedPath(path, language));
    setShowMobileMenu(false);
    setShowMobileIndustryDropdown(false);
  };

  const handleLinkClick = (path) => {
    setShowIndustryDropdown(false);
    navigate(getLocalizedPath(path, language));
  };

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    if (path === '/companies') {
      return location.pathname === '/companies' || industriesData.some(industry => location.pathname === industry.path);
    }
    return location.pathname === path;
  };

  // 搜索的api处理函数
  const handleSearch = async () => {
    const query = searchInputRef.current.value.trim(); // 去除前后空格
    setSearchPerformed(true);
    setNotFound(false);

    if (query) {
      try {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        // const response = await fetch(`${baseUrl}/company/search?query=${query}`);
        const url = getLocalizedUrl(baseUrl, language, `company/search?query=${query}`);
        const response = await fetch(url);

        if (!response.ok) {
          if (response.status === 404) {
            console.log('搜索失败: 没有找到匹配的公司');
            setSearchResults([]); // 保存空数据
            setNotFound(true);
            return;
          }
          throw new Error(`请求失败，状态码: ${response.status}`);
        }

        const data = await response.json();
        setSearchResults(data); // 将结果保存到状态中
      } catch (error) {
        console.error('搜索失败:', error);
      }
    }
  };

  // 点击公司后关闭搜索框
  const handleSelect = () => {
    setShowSearch(false); // 点击结果后关闭搜索框
    setSearchResults([]); // 清空搜索结果
    setSearchPerformed(false);
  };

  // English Version
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  // Language toggle function
  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'cn' : 'en'; // 切换语言
    console.log('Changing language to:', newLanguage);
    dispatch(setLanguage(newLanguage)); // 更新语言状态

    // 获取当前路径
    const currentPath = location.pathname;

    // 如果当前是英文，且切换到中文，则加上 '/cn'
    if (newLanguage === 'cn' && !currentPath.startsWith('/cn')) {
      // navigate(`/cn${currentPath}`, { replace: true }); // 将当前路径修改为以 '/cn' 开头，并强制刷新
      window.location.href = `/cn${currentPath}`;
    } else if (newLanguage === 'en' && currentPath.startsWith('/cn')) {
      // 如果当前是中文路径，且切换回英文，则移除 '/cn'
      // navigate(currentPath.replace('/cn', ''), { replace: true }); // 将当前路径修改为英文路径，并强制刷新
      window.location.href = currentPath.replace('/cn', '');
    }
  };

  // Language selection effect
  useEffect(() => {
    const currentPath = location.pathname;

    // 如果路径以 '/cn' 开头，设置语言为中文，否则为英文
    if (currentPath.startsWith('/cn') && language !== 'cn') {
      dispatch(setLanguage('cn'));
    } else if (!currentPath.startsWith('/cn') && language !== 'en') {
      dispatch(setLanguage('en'));
    }
  }, [location.pathname, dispatch, language]);

  const headerContent = LanguageData[language].header; // 获取当前语言的 header 内容
  const industriesData = LanguageData[language].industries; // 获取当前语言的 industries 内容

  const getLocalizedPath = (path, language) => {
    return language === 'cn' ? `/cn${path}` : path;
  };

  return (
    <div className="fixed top-0 left-0 w-full z-50 bg-gray-white py-2 font-sans text-gray-600">
      {showIndustryDropdown && (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-md z-40 transition-opacity duration-300"></div>
      )}
      <div className="flex items-center justify-between px-2 md:px-4 lg:px-6 relative z-50">
        <Link
          to={getLocalizedPath(`/`, language)}
          className="flex items-center gap-2"
          onClick={() => setShowMobileMenu(false)}
        >
          <img src={logo} alt="logo" className="h-10" />
          <span className="text-sm lg:text-base font-extrabold text-black">{headerContent.slogan}</span>
        </Link>

        <div className={`hidden md:flex items-center ${showSearch ? 'hidden' : ''}`}>
          <ul className="flex md:pr-12 lg:pr-20 items-center space-x-4 text-xs md:text-sm font-normal text-center">
            <li>
              <Link
                to={getLocalizedPath(`/`, language)}
                className="hover:bg-yellow px-4 py-1 rounded-lg hover:text-white transition-colors duration-200">
                {headerContent.home}
              </Link>
              {(isActive('/') || isActive('/cn/')) && <hr className="w-1/2 h-0.5 bg-yellow border-0 mx-auto" />}
            </li>
            <li className="relative group">
              <div
                ref={industryTextRef}
                className="px-4 py-2 -mx-4 -my-2"
                onMouseEnter={handleIndustryTextEnter}
                onMouseLeave={handleIndustryTextLeave}
              >
                <span className="cursor-pointer hover:bg-yellow-500 px-4 py-1 rounded-lg hover:text-white transition-colors">{headerContent.explore}</span>
                {(isActive('/companies') || isActive('/cn/companies')) && <hr className="w-1/2 h-0.5 bg-yellow border-0 mx-auto" />}
              </div>

              {!isMobile && (
                <div
                  ref={industryDropdownRef}
                  className={`absolute left-1/2 transform -translate-x-1/2 mt-2 bg-white rounded-lg shadow-lg py-1 z-50 transition-all duration-500 ease-out ${showIndustryDropdown ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-4'}`}
                  style={{ width: '400px' }}
                  onMouseEnter={handleDropdownEnter}
                  onMouseLeave={handleDropdownLeave}
                >
                  <div className="grid grid-cols-3 gap-2 p-4">
                    <span
                      onClick={() => handleLinkClick('/companies')}
                      className="col-span-3 text-center px-4 py-2 text-sm text-gray-600 hover:bg-yellow rounded-full hover:text-white hover:rounded-full transition-colors duration-200 cursor-pointer"
                    >
                      {industriesData[0].name}
                    </span>
                    {industriesData.slice(1).map((industry) => (
                      <span
                        key={industry.name}
                        onClick={() => handleLinkClick(industry.path)}
                        className="text-center px-4 py-2 text-sm text-gray-600 hover:bg-yellow rounded-full hover:text-white hover:rounded-full transition-colors duration-200 cursor-pointer"
                      >
                        {industry.name}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </li>
            <li>
              <Link
                to={getLocalizedPath(`/calendar`, language)}
                className="hover:bg-yellow hover:text-white px-4 py-1 rounded-lg transition-colors duration-200">
                {headerContent.calendar}
              </Link>
              {(isActive('/calendar') || isActive('/cn/calendar')) && <hr className="w-1/2 h-0.5 bg-yellow border-0 mx-auto" />}
            </li>
            <li>
              <Link
                to={getLocalizedPath(`/contact`, language)}
                className="hover:bg-yellow hover:text-white px-4 py-1 rounded-lg transition-colors duration-200">
                {headerContent.about}
              </Link>
              {(isActive('/contact') || isActive('/cn/contact')) && <hr className="w-1/2 h-0.5 bg-yellow border-0 mx-auto" />}
            </li>
          </ul>
        </div>

        <div className="flex items-center">
          <img
            src={helpIcon}
            className="cursor-pointer mr-4"
            onClick={() => navigate(getLocalizedPath(`/QA`, language))}
            size={27}
          />
          <img
            src={searchIcon}
            className="cursor-pointer mr-4"
            onClick={() => {
              setShowSearch(true);
              setSearchResults([]); // 点击搜索图标时清空搜索结果
            }}
            size={22.5}
          />
          <div className='flex relative mr-4'>
            {/* 小人暂时不显示，以防有人注册 */}
            {/* <FaUserCircle
              className="cursor-pointer"
              onClick={handleUserIconClick}
              size={22.5}
            /> */}
            {showUserDropdown && (
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-4 bg-white shadow-lg rounded-lg py-2 z-50 w-24 user-dropdown">
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-center"
                  onClick={() => navigate('/personCRM')}
                >
                  {headerContent.account}
                </button>
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-center"
                  onClick={handleLogout}
                >
                  {headerContent.logout}
                </button>
              </div>
            )}
          </div>
          {/* <MdHelpOutline
            className="cursor-pointer mr-4"
            onClick={() => navigate('/QA')}
            size={27}
          />
          <FaSearch
            className="cursor-pointer mr-4"
            onClick={() => {
              setShowSearch(true);
              setSearchResults([]); // 点击搜索图标时清空搜索结果
            }}
            size={22.5}
          /> */}
          <button onClick={toggleLanguage} className="p-1 w-7 h-7 mr-3 rounded-lg text-xs border border-black hover:bg-yellow">
            {language === 'en' ? 'CN' : 'EN'}
          </button>
          {isMobile && (
            <FaBars
              className="md:hidden cursor-pointer"
              onClick={handleMobileMenuToggle}
              size={22.5}
            />
          )}
        </div>

        {showSearch && (
          <div className="fixed inset-0 flex items-start justify-center z-50">
            <div
              className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-md z-40"
              onClick={() => setShowSearch(false)}
            ></div>
            <div
              className="relative z-50 flex flex-col items-center w-full max-w-lg" // 最大宽度可根据需要调整
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex px-8 md:px-0 w-full mb-2 items-center mt-4">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="flex-1 px-6 p-2 rounded-full focus:outline-none bg-white text-black text-xs md:text-sm lg:text-base"
                  placeholder={language==="en"? "Search for companies, industries, or locations":"搜索公司名，行业，或者地区"}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch(); // 按下回车键搜索
                    }
                  }}
                />
                <FaArrowRight
                  className="absolute right-10 md:right-5 h-4 w-4 md:h-5 md:w-5 cursor-pointer text-gray-500 hover:text-gray-700"
                  onClick={handleSearch} // 点击箭头搜索
                />
              </div>

              {/* 渲染搜索结果 */}
              {searchPerformed && (
                <SearchResults
                  results={searchResults}
                  onSelect={handleSelect}
                  notFound={notFound}
                />
              )}
            </div>
          </div>
        )}

        {showLogin && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 backdrop-blur-lg z-50">
            <div
              ref={loginComponentRef}
              className={`max-w-md w-4/5 md:w-full transform transition-transform duration-500`}
            >
              <LoginComponent closeLogin={() => setShowLogin(false)} />
            </div>
          </div>
        )}

      </div>

      {isMobile && (
        <div
          className={`md:hidden bg-gray-black absolute top-full left-0 w-full z-50 overflow-hidden transition-all duration-300 ease-in-out ${showMobileMenu ? 'max-h-screen' : 'max-h-0'}`}
        >
          <ul className="py-2">
            <li>
              <Link
                to={getLocalizedPath(`/`, language)}
                className="block px-4 py-2 hover:bg-yellow"
                onClick={() => setShowMobileMenu(false)}>{headerContent.home}
              </Link>
            </li>
            <li>
              <span className="block px-4 py-2 hover:bg-yellow cursor-pointer" onClick={handleMobileIndustryClick}>{headerContent.explore}</span>
              <div className={`overflow-hidden transition-all duration-300 ease-in-out ${showMobileIndustryDropdown ? 'max-h-screen' : 'max-h-0'}`}>
                <ul className="pl-8 bg-gray-black">
                  {industriesData.map((industry) => (
                    <li key={industry.name}>
                      <span
                        className="block px-4 py-2 text-xs hover:bg-yellow cursor-pointer"
                        onClick={() => handleMobileIndustryItemClick(industry.path)}
                      >
                        {industry.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li>
              <Link
                to={getLocalizedPath(`/calendar`, language)}
                className="block px-4 py-2 hover:bg-yellow"
                onClick={() => setShowMobileMenu(false)}>
                {headerContent.calendar}
              </Link>
            </li>
            <li>
              <Link
                to={getLocalizedPath(`/contact`, language)}
                className="block px-4 py-2 hover:bg-yellow"
                onClick={() => setShowMobileMenu(false)}>
                {headerContent.about}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;