import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LanguageData } from '../Common/LanguageData';
import { useDispatch, useSelector } from 'react-redux';
import arrowPNG from '../../Assets/gray_arrow.png';
import HomeGoMoreLink from "../Common/HomeGoMoreLink";
import HomeSectionWrapper from "../Common/HomeSectionWrapper";

const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

const Activity = () => {
  const [events, setEvents] = useState([]);
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const landingData = LanguageData[language].landing; // 获取当前语言的 landing 内容

  const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:4000";

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // 检查是否有缓存数据
        const cachedEvents = localStorage.getItem("events");
        if (cachedEvents) {
          setEvents(JSON.parse(cachedEvents));
        } else {
          const response = await fetch(`${baseUrl}/events`);
          if (!response.ok) {
            throw new Error("网络错误，无法获取活动。");
          }
          const data = await response.json();
          setEvents(data); // 将获取到的新闻数据存入状态

          // 缓存数据
          localStorage.setItem("events", JSON.stringify(data));
        }
      } catch (err) {
        console.error(err.message); // 处理错误
      }
    };

    fetchEvents();
  }, [baseUrl]); // 当 baseUrl 变化时重新请求活动

  //计算活动是否已经过期的辅助函数
  const isEventExpired = (startDate) => {
    const [day, month, year] = startDate.split("/");
    const eventDate = new Date(`${year}-${month}-${day}`); // 活动开始日期
    const today = new Date();
    return today > eventDate;
  };

  const trimDetailsAndHandleEllipsis = (event) => {
    const details = language === "en" ? event.Details_en : event.Details; // 提取 details
    if (!details) return ""; // 如果 details 不存在，直接返回空字符串

    const maxLength = window.innerWidth <= 640 ? 25 : 40; // 设置最大长度
    const trimmedDetails = details.slice(0, maxLength); // 截取内容
    const shouldShowEllipsis = details.length > maxLength; // 是否需要显示省略号

    return (
      <>
        {trimmedDetails}
        {shouldShowEllipsis ? "..." : ""}
      </>
    );
  }

  return (
    <HomeSectionWrapper title={landingData.eventsTitle} to={getLocalizedPath(`/calendar`, language)} linkText={landingData.more}>
      <div className="grid w-full grid-cols-2 md:grid-cols-3 gap-6 flex-grow overflow-y-auto">
        {events.slice(0, 6).map((event, index) => (
          <Link
            key={event.news_id}
            // to={`/calendar/${event.event_id}`}
            to={getLocalizedPath(`/calendar/${event.event_id}`, language)}

            className="group block shadow-md aspect-[3/2] relative overflow-hidden"
          // className={`block shadow-md aspect-[3/2] relative overflow-hidden
          //             ${index === 0
          //     ? "col-span-2 md:col-span-1 justify-self-center"
          //     : ""
          //   }`}
          >
            <div
              className="flex flex-col justify-center items-center p-2 h-full w-full"
              style={{
                backgroundImage: event.Image ? `url(${event.Image})` : "none",
                backgroundColor: event.Image ? "transparent" : "white",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 hover:bg-yellow"></div>
              {
                // 如果活动已经过期，展示“过期”标签 
                isEventExpired(event.Start_date) && (
                  <div className="absolute top-2 right-2 bg-gray-300 z-20 text-black text-xs px-2 py-1 rounded">
                    {landingData.expired}
                  </div>
                )}
              <h3 className="text-yellow text-[10px] sm:text-sm font-semibold mb-1 z-10 text-center group-hover:text-white">
                {language === "en" ? event.Event_en : event.Event}
              </h3>

              <p className="text-white text-[10px] sm:text-xs z-10 text-center sm:mt-3">
                {
                  trimDetailsAndHandleEllipsis(event)
                }
              </p>
              <p className="text-gray-200 text-[10px] sm:text-xs z-10 text-center sm:mt-3">
                {event.Start_date}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </HomeSectionWrapper>
  )
  // return (
  //   <div className="p-4 sm:px-6 lg:px-12 h-full flex flex-col items-center gap-5">
  //     {/* <div className="self-end flex items-center justify-between mb-4 flex-row-reverse">
  //       <HomeSectionTitle enTitle={LanguageData.en.landing.eventsTitle} zhTitle={LanguageData.cn.landing.eventsTitle} />
  //     </div> */}
  //     <HomeSectionTitle className="self-end" enTitle={LanguageData.en.landing.eventsTitle} zhTitle={LanguageData.cn.landing.eventsTitle} />

  // <div className="grid w-full grid-cols-2 md:grid-cols-3 gap-3 flex-grow overflow-y-auto">
  //   {events.slice(0, 6).map((event, index) => (
  //     <Link
  //       key={event.news_id}
  //       // to={`/calendar/${event.event_id}`}
  //       to={getLocalizedPath(`/calendar/${event.event_id}`, language)}

  //       className="group block shadow-md aspect-[3/2] relative overflow-hidden"
  //     // className={`block shadow-md aspect-[3/2] relative overflow-hidden
  //     //             ${index === 0
  //     //     ? "col-span-2 md:col-span-1 justify-self-center"
  //     //     : ""
  //     //   }`}
  //     >
  //       <div
  //         className="flex flex-col justify-center items-center p-2 h-full w-full"
  //         style={{
  //           backgroundImage: event.Image ? `url(${event.Image})` : "none",
  //           backgroundColor: event.Image ? "transparent" : "white",
  //           backgroundSize: "cover",
  //           backgroundPosition: "center",
  //         }}
  //       >
  //         <div className="absolute inset-0 bg-black bg-opacity-50 hover:bg-yellow"></div>
  //         {
  //           // 如果活动已经过期，展示“过期”标签 
  //           isEventExpired(event.Start_date) && (
  //             <div className="absolute top-2 right-2 bg-gray-300 z-20 text-black text-xs px-2 py-1 rounded">
  //               {landingData.expired}
  //             </div>
  //           )}
  //         <h3 className="text-yellow text-[10px] sm:text-sm font-semibold mb-1 z-10 text-center group-hover:text-white">
  //           {language === "en" ? event.Event_en : event.Event}
  //         </h3>

  //         <p className="text-white text-[10px] sm:text-xs z-10 text-center sm:mt-3">
  //           {
  //             trimDetailsAndHandleEllipsis(event)
  //           }
  //         </p>
  //         <p className="text-gray-200 text-[10px] sm:text-xs z-10 text-center sm:mt-3">
  //           {event.Start_date}
  //         </p>
  //       </div>
  //     </Link>
  //   ))}
  // </div>

  //     <HomeGoMoreLink to={getLocalizedPath(`/calendar`, language)} text={landingData.more} />

  //     {/* <Link
  //       // to="/calendar"
  //       to={getLocalizedPath(`/calendar`, language)}
  //       className="flex items-center justify-center px-6 py-2 border border-gray-300 rounded-full text-gray-500 hover:bg-gray-100 hover:text-gray-700 transition"
  //     >
  //       {landingData.more} &gt;&gt;&gt;
  //     </Link> */}

  //     {/* <button className="flex inline-flex items-center justify-center px-6 py-2 border border-gray-300 rounded-full text-gray-500 hover:bg-gray-100 hover:text-gray-700 transition">
  //       {landingData.more}
  //       <img className="w-10" src={arrowPNG} />
  //     </button> */}
  //   </div>
  // )
};

export default Activity;
