import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLatestNews } from './newsSlice';
import { LanguageData } from '../Common/LanguageData';
import HomeSectionWrapper from "../Common/HomeSectionWrapper";
import news_image from '../../Assets/news-image.jpg';

// Helper function to dynamically add /cn prefix based on language
const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

const News = () => {
  const dispatch = useDispatch(); // 用于分发 Redux 异步操作
  const { newsList, loading, error } = useSelector((state) => state.news); // 从 Redux 中选择状态
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const landingData = LanguageData[language].landing; // 获取当前语言的 landing 内容
  const [visibleNewsCount, setVisibleNewsCount] = useState(3); //展示新闻条数, 默认展示3条
  const today = new Date();//当日日期

  useEffect(() => {
    dispatch(fetchLatestNews()); // 触发获取新闻的异步操作
  }, []);

  useEffect(() => {
    const updateNewsCount = () => {
      if (window.innerWidth < 768) {
        setVisibleNewsCount(2); // 小屏幕展示 2 条新闻
      } else {
        setVisibleNewsCount(3); // 大屏幕展示 3 条新闻
      }
    };

    updateNewsCount(); // 初始化时更新新闻条数
    window.addEventListener("resize", updateNewsCount);

    return () => {
      window.removeEventListener("resize", updateNewsCount);
    };
  }, []);

  return (
    <HomeSectionWrapper title={landingData.newsTitle} to={getLocalizedPath(`/news`, language)} linkText={landingData.more}>

      <div className="mx-auto grid grid-cols-[4fr_3fr] md:grid-cols-[4fr_3fr_3fr]">
        {newsList.slice(0, visibleNewsCount).map((news, index) => (
          <Link
            key={news.news_id}
            to={getLocalizedPath(`/news/${news.news_id}`, language)}
            className="group block shadow-md relative"
          >
            {index === 0 && (
              <div className="absolute top-0 left-0 bg-yellow-700 text-white p-2 md:p-4 shadow-lg flex flex-col items-center">
                <div className="text-xs font-medium">{today.getFullYear() + '-' + (today.getMonth() + 1)}</div>
                <div className="text-2xl font-bold">{today.getDate()}</div>
              </div>
            )}
            <div className={`bg-white flex flex-col ${index === 1 ? "flex-col-reverse" : ""}`}>
              {/* 新闻图片 */}
              <div className="h-32 md:h-48">
                <img src={news_image} className='w-full h-full object-cover'></img>
              </div>
              {/* 新闻标题内容 */}
              <div className="h-32 md:h-48 flex flex-col">
                <h3 className="text-sm font-semibold m-4 md:m-6 h-16 leading-relaxed overflow-hidden text-ellipsis line-clamp-2 whitespace-normal">{language === "en" ? news.Title_EN : news.Title}</h3>
                <p className="text-xs text-gray-500 mx-4 md:mx-6 mb-4 h-16 md:h-28 leading-relaxed overflow-hidden text-ellipsis line-clamp-2 md:line-clamp-4 whitespace-normal">{language === "en" ? news.Content_EN : news.Content}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </HomeSectionWrapper>
  );
};

export default News;
