import React from 'react';
import { Link } from 'react-router-dom';
import { formatForUrl } from '../../Utils/companyUtils';
import { useSelector } from 'react-redux'; // 导入 Redux 的 useSelector

const SearchResults = ({ results, onSelect, notFound }) => {
  const currentLanguage = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  const getLocalizedPath = (path, language) => {
    return language === 'cn' ? `/cn${path}` : path;
  };

  return (
    <div className="w-full mt-2 p-4 bg-white shadow-md rounded-lg max-w-xl max-h-[600px] overflow-y-auto">
      {notFound ? (
        <div className="text-gray-600 text-center py-2">
          {currentLanguage === 'en' ? 'No companies found' : '没有找到相关公司'}
        </div>
      ) : (
        <ul>
          {results.map((company) => (
            <li key={company.id} className="py-2 border-b flex">
              <Link 
                to={getLocalizedPath(`/company/${formatForUrl(company.name_en)}`, currentLanguage)} 
                target="_blank" 
                rel="noopener noreferrer"
                state={{ company }}
                className="flex-1 text-center"
                onClick={() => onSelect()} // 点击时关闭搜索框
              >
                <p className="text-gray-600 text-xs sm:text-sm md:text-md">
                  {company.name}
                </p>
              </Link>
              <div className="w-1/4 text-center">
                <p className="text-gray-600 text-xs sm:text-sm md:text-md">
                  {company.industry}
                </p>
              </div>
              <div className="w-1/4 text-center">
                <p className="text-gray-600 text-xs sm:text-sm md:text-md">
                  {company.location}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchResults;
