import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import arrow_icon from '../../Assets/breadcrum_arrow.png';
import { useDispatch, useSelector } from 'react-redux';

// 定义路径与中文名称的映射表
const breadcrumbMapCn = {
  '/cn/company': '公司信息',
  '/cn/companies': '公司信息',
  '/cn/companies/realestate': '地产开发',
  '/cn/companies/design': '建筑设计',
  '/cn/companies/builder': '房屋建造',
  '/cn/companies/material': '建筑材料',
  '/cn/companies/decoration': '房屋装修',
  '/cn/companies/agency': '房产经纪',
  '/cn/companies/mortgage': '房屋信贷',
  '/cn/companies/law': '法律咨询',
  '/cn/companies/accounting': '会计事务',
  '/cn/calendar': '活动日历',
  '/cn/news': '新闻',
  '/cn/QA': '常见问题',
};

const breadcrumbMapEn = {
  '/company': 'Company Information',
  '/companies': 'Company Information',
  '/companies/realestate': 'Real Estate Development',
  '/companies/design': 'Architectural Design',
  '/companies/builder': 'House Construction',
  '/companies/material': 'Building Materials',
  '/companies/decoration': 'House Decoration',
  '/companies/agency': 'Real Estate Agency',
  '/companies/mortgage': 'Home Loans',
  '/companies/law': 'Legal Consulting',
  '/companies/accounting': 'Accounting Services',
  '/calendar': 'Event Calendar',
  '/news': 'News',
  '/QA': 'FAQ',
};


const Breadcrums = ({ color, additionalText }) => {
  const location = useLocation();
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  // 获取当前路径并按 '/' 分割
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div className='flex items-center text-sm relative flex-wrap w-[80%] mx-[20px] md:mx-[50px] rounded-lg pt-4'>

      <Link to={language === "en"? "/":"/cn"}>
        <span className={`hover:text-yellow cursor-pointer ${color}`}>{language === "en" ? "Home":"首页"}</span>
      </Link>

      {/* 动态生成面包屑 */}
      {pathnames.map((_, index) => {
        // 构建每个面包屑的完整路径
        const to = `/${pathnames.slice(0, index + 1).join('/')}`.replace('company', 'companies');
        const isLast = index === pathnames.length - 1;
        const breadcrumbName = language === "en"? breadcrumbMapEn[to]: breadcrumbMapCn[to];

        // 检查当前层级是否包含数字
        const hasNumber = /\d/.test(pathnames[index]);

        // 如果有对应中文名，则使用对应的中文名
        // 如果是最后一层并且包含数字，则将 additionalText 渲染在最后
        if (isLast && (hasNumber || additionalText)) {
          return (
            <React.Fragment key={to}>
              <img src={arrow_icon} alt="" className="w-2 h-2 mx-2" />
              <span className="font-semibold text-yellow">{additionalText}</span>
            </React.Fragment>
          );
        }

        // 如果映射表里有对应中文名，则显示，否则保持原样
        if (!breadcrumbName) return null;

        return (
          <React.Fragment key={to}>
            <img src={arrow_icon} alt="" className="w-2 h-2 mx-2" />
            {isLast ? (
              <span className="font-semibold text-yellow">{breadcrumbName}</span>
            ) : (
              <Link to={to}>
                <span className={`hover:text-yellow cursor-pointer ${color}`}>
                  {breadcrumbName}
                </span>
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrums;
