import React, { useRef, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./Pages.css";
import EventLists from "../Components/Events/EventLists";
import { useDispatch, useSelector } from 'react-redux';
import EventHoverPopup from "../Components/Events/EventHoverPopup";
import HeadImage from '../Assets/CompanyCalendar/head-image.jpg'
import { LanguageData } from '../Components/Common/LanguageData';
import PageHeader from "../Components/Common/PageHeader";
import SectionTitle from '../Components/Common/SectionTitle'
import Breadcrums from '../Components/Common/Breadcrums';


const fetchEvents = async () => {
  const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:4000";
  const response = await fetch(`${baseUrl}/events`);
  const data = await response.json();

  // 转换后端事件数据为 FullCalendar 需要的格式
  return data.map((event) => ({
    id: event.event_id,
    title: event.Event,
    title_en: event.Event_en,
    description: event.Details,
    description_en: event.Details_en,
    start: `${convertDate(event.Start_date)}`, // 将日期和时间组合成ISO格式
    end: `${convertDate(event.End_date)}`,
    time: `${event.Start_time} - ${event.End_time}`,
    extendedProps: {
      address: event.Address,
      details: event.Details,
      image: event.Image,
      host: event.Host,
      host_en: event.Host_en,
      ticket: event.Ticket,
      city: event.City,
    },
    url: `/calendar/${event.event_id}`, // 每个活动对应的跳转URL
  }));
};


const handleEventClick = (info) => {
  // 阻止默认跳转行为（如果存在默认的url属性）
  info.jsEvent.preventDefault();

  // 跳转到事件的对应页面
  if (info.event.url) {
    window.location.href = info.event.url;
  }
};

// 将 "DD/MM/YYYY" 转换成 "YYYY-MM-DD" 格式
const convertDate = (dateString) => {
  const [day, month, year] = dateString.split("/");
  return `${year}-${month}-${day}`;
};

// 将时间转换为24小时制的字符串
const convertTime = (timeString) => {
  const [time, period] = timeString.split(" ");
  let [hours, minutes] = time.split(":");

  if (period === "下午" && hours !== "12") {
    hours = parseInt(hours) + 12;
  } else if (period === "上午" && hours === "12") {
    hours = "00";
  }

  return `${hours}:${minutes}`;
};

const CalendarComponent = () => {
  const calendarRef = useRef(null);
  const [calendarTitle, setCalendarTitle] = useState("");
  const [events, setEvents] = useState([]); // 初始为空数组
  const popupRef = useRef()

  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言  
  const currentLanguagueData = LanguageData[language]

  useEffect(() => {
    const getEvents = async () => {
      const fetchedEvents = await fetchEvents(); // 获取并转换事件数据
      setEvents(fetchedEvents); // 设置事件
    };

    getEvents(); // 组件加载时获取事件

    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setCalendarTitle(calendarApi.view.title);
    }
  }, []);

  const handlePrev = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCalendarTitle(calendarApi.view.title);
  };

  const handleNext = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCalendarTitle(calendarApi.view.title);
  };

  const handleToday = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    setCalendarTitle(calendarApi.view.title);
  };

  const handleViewChange = (view) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(view);
    setCalendarTitle(calendarApi.view.title);
  };

  return (
    <div className="bg-gray-white min-h-screen pb-12">
      <EventHoverPopup ref={popupRef} language={language} />


      <PageHeader title={currentLanguagueData.landing.eventsTitle} image={HeadImage} />

      <section className="px-[15%] flex flex-col">
        <Breadcrums color="text-gray-700" />
        {/* <h2 className="self-end text-3xl text-black my-20">{currentLanguagueData.landing.eventsTitle}</h2> */}
        <SectionTitle title={currentLanguagueData.landing.eventsTitle} className='my-10 self-end' />

      </section>
      <section className="flex flex-col mx-4 md:mx-[25%]">
        <div className="bg-white rounded-2xl shadow-md overflow-hidden">
          {/* 自定义工具栏 */}
          <div className="flex flex-col items-center mt-4 p-4">
            {/* 日历标题 */}
            <div className="w-full text-center mb-4">
              <h2 className="text-xl font-bold">{calendarTitle}</h2>
            </div>
            {/* 包含按钮的框 */}
            <div className="bg-gray-50 p-2 rounded-xl shadow-inner w-full overflow-x-auto">
              <div className="flex justify-center items-center whitespace-nowrap">
                {/* Today 按钮 */}
                <button
                  className="mx-1 px-2 py-1 border border-yellow-500 text-yellow-500 text-xs sm:text-sm rounded-full hover:bg-yellow-100 active:bg-yellow-100 transition duration-200 flex-shrink-0"
                  onClick={handleToday}
                >
                  {language === 'en' ? 'Today' : '今天'}
                </button>
                {/* 增加间距 */}
                <div className="w-px h-4 bg-gray-300 mx-1"></div>
                {/* 中间三个按钮 */}
                <button
                  className="mx-1 px-2 py-1 bg-yellow-500 text-black text-xs sm:text-sm rounded-full hover:bg-yellow-600 active:bg-yellow-700 transition duration-200 flex-shrink-0"
                  onClick={handlePrev}
                >
                  &lt;
                </button>
                <button
                  className="mx-1 px-2 py-1 bg-yellow-500 text-black text-xs sm:text-sm rounded-full hover:bg-yellow-600 active:bg-yellow-700 transition duration-200 flex-shrink-0"
                  onClick={() => handleViewChange("dayGridMonth")}
                >
                  {language === 'en' ? 'Month' : '月份'}
                </button>
                <button
                  className="mx-1 px-2 py-1 bg-yellow-500 text-black text-xs sm:text-sm rounded-full hover:bg-yellow-600 active:bg-yellow-700 transition duration-200 flex-shrink-0"
                  onClick={handleNext}
                >
                  &gt;
                </button>
                {/* 增加间距 */}
                <div className="w-px h-4 bg-gray-300 mx-1"></div>
                {/* Week 按钮 */}
                <button
                  className="mx-1 px-2 py-1 border border-yellow-500 text-yellow-500 text-xs sm:text-sm rounded-full hover:bg-yellow-100 active:bg-yellow-100 transition duration-200 flex-shrink-0"
                  onClick={() => handleViewChange("dayGridWeek")}
                >
                  {language === 'en' ? 'Week' : '周'}
                </button>
              </div>
            </div>
          </div>
          {/* FullCalendar 组件 */}
          <div className="p-4">
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              events={events}
              headerToolbar={false}
              views={{
                dayGridMonth: { fixedWeekCount: false },
                dayGridWeek: {
                  titleFormat: { month: "long", year: "numeric" },
                  dayHeaderFormat: { weekday: "short" },
                },
              }}
              locale={language === 'en' ? 'en' : 'zh-cn'} // 根据语言状态动态设置 FullCalendar 的 locale
              datesSet={(dateInfo) => setCalendarTitle(dateInfo.view.title)}
              eventClick={handleEventClick} // 绑定事件点击处理函数
              eventMouseEnter={(mouseEnterInfo) => {
                console.log(popupRef);

                popupRef.current.display(mouseEnterInfo.event.id)
              }}
              eventMouseLeave={(mouseLeaveInfo) => {
                popupRef.current.close()
              }}
            />
          </div>
        </div>
        {/* 活动列表 */}
        {/* <div className="mt-8 mx-4 sm:mx-8 md:mx-24 lg:mx-36"> */}
        <div className="mt-8">
          <EventLists
            events={events}
            className="mb-24 bg-white rounded-2xl shadow-md p-4"
          />
        </div>
      </section>
      {/* </header> */}
      {/* <div className="bg-white mx-8 sm:mx-16 md:mx-48 lg:mx-72 rounded-2xl shadow-md overflow-hidden"> */}

    </div >
  );
};

export default CalendarComponent;
