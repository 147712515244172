import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const SearchBar = ({ onSearch }) => {
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  const [inputValue, setInputValue] = useState('');

  const handleSearchClick = () => {
    if (inputValue.trim()) {
      onSearch(inputValue.trim());
    }
  };  

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleClearSearch = () => {
    setInputValue('');
    onSearch(''); // 触发一个空字符串搜索，effectively清除搜索结果
  };

  return (
    <div className="w-full flex items-center justify-center my-6">
      <input
        type="text"
        placeholder={language === 'en' ? 'Search for ...' : '搜索...'}
        className="w-2/3 p-2 border-2 border-yellow focus:outline-none focus:border-yellow rounded-xl"
        style={{ caretColor: 'black', color: 'black' }}
        value={inputValue}
        onChange={handleInputChange}
      />
      <button 
        className="ml-4 px-4 py-2 bg-yellow text-black rounded-xl hover:bg-yellow-700 transition duration-200"
        onClick={handleSearchClick}
      >
        {language === 'en' ? 'Search' : '搜索'}
      </button>
      <button
        className="ml-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-xl hover:bg-gray-200 transition duration-200"
        onClick={handleClearSearch}
      >
        {language === 'en' ? 'Clear' : '清除'}
      </button>
    </div>
  );
};

export default SearchBar;