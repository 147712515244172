import React, { useState } from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const [inputPage, setInputPage] = useState(''); // State to manage user input

  const getPageNumbers = () => {
    const pages = [];
    
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, '...', totalPages);
      } else if (currentPage > 3 && currentPage < totalPages - 2) {
        pages.push(1, 2, '...', currentPage, '...', totalPages);
      } else {
        pages.push(1, 2, '...', totalPages - 2, totalPages - 1, totalPages);
      }
    }
    
    return pages;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 1 && value <= totalPages) {
      setInputPage(value);
    } else {
      setInputPage('');
    }
  };

  const handleJumpToPage = (e) => {
    e.preventDefault();
    if (inputPage) {
      onPageChange(Number(inputPage));
      setInputPage('');
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center space-x-2 mb-6">
      <div>
        {/* First button */}
        <button
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
          className={`p-2 rounded ${currentPage === 1 ? 'text-gray-400' : 'text-black hover:bg-yellow'}`}
        >
          &lt;&lt;
        </button>

        {/* Previous button */}
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`p-2 rounded ${currentPage === 1 ? 'text-gray-400' : 'text-black hover:bg-yellow'}`}
        >
          &lt;
        </button>

        {/* Page numbers */}
        {getPageNumbers().map((page, index) => (
          <button
            key={index}
            onClick={() => typeof page === 'number' && onPageChange(page)}
            className={`p-2 text-xs sm:text-base md:text-md rounded ${currentPage === page ? 'underline' : ''} ${
              typeof page === 'number' ? 'text-black hover:bg-yellow' : 'text-gray-400 cursor-default'
            }`}
          >
            {page}
          </button>
        ))}

        {/* Next button */}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`p-2 rounded ${currentPage === totalPages ? 'text-gray-400' : 'text-black hover:bg-yellow'}`}
        >
          &gt;
        </button>

        {/* Last button */}
        <button
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
          className={`p-2 rounded ${currentPage === totalPages ? 'text-gray-400' : 'text-black hover:bg-yellow'}`}
        >
          &gt;&gt;
        </button>
      </div>

      {/* Jump to page input */}
      <form onSubmit={handleJumpToPage} className="flex items-center space-x-2">
        <input
          // type="number"
          value={inputPage}
          onChange={handleInputChange}
          className="p-2 w-8 sm:w-12 border rounded text-center text-xs sm:text-sm"
          placeholder=""
          min="1"
          max={totalPages}
        />
      </form>
    </div>
  );
};

export default Pagination;
