import React from 'react';

const FeatureBar = ({ imageUrl, link }) => {
  return (
    <div className="w-full shadow-md overflow-hidden">
      <a href={link} className="block" target="_blank" rel="noopener noreferrer" >
        <div className="relative w-full pb-[20%]"> {/* 5:1 Aspect Ratio */}
          <img
            src={imageUrl}
            alt="Feature Bar"
            className="absolute inset-0 w-full h-full object-cover object-center"
          />
        </div>
      </a>
    </div>
  );
}

export default FeatureBar;