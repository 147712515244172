import React from 'react'
import { RiHomeOfficeLine } from "react-icons/ri";
const Agency = () => {
    return (
      <div>
        <RiHomeOfficeLine className="w-16 h-16" /> 
      </div>
    );
  };
  
  export default Agency;