import { Link } from "react-router-dom";
import { isValidElement, cloneElement } from 'react'
import SectionTitle from './SectionTitle'

/**
 * Provide the title, go more link and side margin for each section in home page.
 * @returns 
 */
export default function HomeSectionWrapper({ title, to, linkText, children }) {
  function addClassNameForChildren(child) {
    if (isValidElement(child)) {
      return cloneElement(child, {
        className: `${child.props.className || ''} max-w-6xl`,
      });
    } else {
      throw new Error('Children should only contain one root element in this component')
    }
    return child;
  }
  return (
    <div className="flex pt-8 flex-col items-center gap-6 px-8 xl:px-60">
      <SectionTitle title={title} className="self-end" />
      {/* max-w-6xl  */}
      {addClassNameForChildren(children)}
      <Link
        to={to}
        className="flex items-center justify-center px-6 py-2 border border-gray-300 rounded-full text-gray-500 hover:bg-yellow hover:text-gray-700 transition"
      >
        {linkText}
      </Link>
    </div >
  )

}