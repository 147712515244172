import React from 'react';
import { Link } from 'react-router-dom';

const UserMessage = () => {
    const messages = [
        {
            type: 'comment',
            content: 'User A commented on your post: Great job!',
            timestamp: '2024-09-24 10:35',
        },
        {
            type: 'eventComment',
            content: 'User B commented on your event: Looking forward to it!',
            timestamp: '2024-09-24 11:00',
        },
        {
            type: 'like',
            content: 'User C liked your comment.',
            timestamp: '2024-09-24 12:15',
        },
    ];

    return (
        <div className="p-6 bg-white shadow-md">
            <h2 className="text-xl md:text-2xl font-bold mb-4">用户消息通知</h2>
            <ul>
                {messages.map((message, index) => (
                    <li key={index} className="mb-4">
                        <div className="flex flex-col lg:flex-row lg:justify-between items-start">
                            <Link to="#" className="text-blue-700">
                                <span className="text-gray-700 text-sm md:text-base">{message.content}</span>
                            </Link>
                            <span className="text-gray-400 text-sm">{message.timestamp}</span>
                        </div>
                        <div className='pt-2'><hr /></div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserMessage;
