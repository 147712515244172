import React from 'react';
import CompanyIntroduction from '../Components/Contact/CompanyIntroduction';
import ContactHeader from '../Components/Contact/ContactHeader';
import BusinessCooperation from '../Components/Contact/BusinessCooperation';
import ContactForm from '../Components/Contact/ContactForm';

const ContactPage = () => {
  return (
    <div className="bg-white">
      <CompanyIntroduction />
      <ContactForm />
    </div>

  );
};

export default ContactPage;