import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNewsById } from '../Components/News/newsSlice';
import { FaCalendarAlt, FaUser } from 'react-icons/fa';
import LivecomfyTop from '../Assets/Livecomfy_Top.png';
import FeatureBar from '../Components/Common/FeatureBar';
import Breadcrums from '../Components/Common/Breadcrums';
import WenMortgage from '../Assets/Wen_Mortgage.jpg';
import FormattedText from '../Components/Common/FormattedText';
import { LanguageData } from '../Components/Common/LanguageData';
import newsdetail_icon from '../Assets/newsdetail-icon.png';
import newspage_background from '../Assets/newspage-background.jpg';
import PageHeader from '../Components/Common/PageHeader';

const NewsDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { newsItem, loading, error } = useSelector((state) => state.news);
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const newsPageData = LanguageData[language].newsPage;

  useEffect(() => {
    dispatch(fetchNewsById(id));
  }, [dispatch, id]);

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">Error: {error}</div>;
  }

  if (!newsItem) {
    return <div className="text-center py-10">News item not found.</div>;
  }

  return (
    <div className="bg-gray-white min-h-screen pt-4">
      {/* 标题部分 */}
      <div className="text-center mb-12">
        <PageHeader title={ newsPageData.newsTitle } image={ newspage_background } titleStyle = "text-yellow" opacity = "opacity-100"/>
      </div>
      <Breadcrums color="text-gray-700" additionalText={language==="en"? "News Detail":"新闻详情"} />
      <hr className="border-t-1 border-gray-300 my-2" />

      <div className="bg-white max-w-4xl mx-auto px-4 py-8 my-8">
        {/* 新闻标题 */}
        <div className='flex items-center justify-center space-x-2 px-10 py-4 mt-8'>
          <img src={newsdetail_icon} alt="icon" class="h-5 hidden md:block" />
          <h1 className="text-xl text-black font-bold text-center">
            {language === "en"? newsItem.title_en : newsItem.title}
          </h1>
          <img src={newsdetail_icon} alt="icon" class="h-5 hidden md:block" />
        </div>

        {/* 新闻日期 */}
        <div className="flex items-center text-gray-600  justify-center underline decoration-yellow-500 decoration-2 underline-offset-8">
          <span className="mr-4 flex items-center">
            {/* {newsItem.date} */}
            {language === "en"? new Date(newsItem.date).toLocaleDateString('en-GB') : newsItem.date }
          </span>
        </div>

        {/* 新闻内容 */}
        <div className="p-10 rounded-lg text-gray-700 leading-relaxed" style={{ minHeight: '400px' }}>
          <FormattedText text={language === "en"? newsItem.content_en : newsItem.content} />
        </div>
      </div>
    </div>
  );
};

export default NewsDetailPage;