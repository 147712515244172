import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { login } from './authSlice';
import { useNavigate } from 'react-router-dom';
import SearchResults from './SearchResults';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';

const LoginComponent = ({ closeLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '', confirmPassword: '' });
  const [version, setVersion] = useState('个人版');
  const [error, setError] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [companyQuery, setCompanyQuery] = useState('');
  const [companyResults, setCompanyResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [verificationStage, setVerificationStage] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);

  const companySearchRef = useRef();

  useEffect(() => {
    setAnimate(true);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

      // If it’s a login request, we proceed with login
      if (isLogin) {
        const response = await fetch(`${baseUrl}/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error('Login failed. Please check your credentials.');
        }

        const data = await response.json();
        const { idToken, refreshToken, userId } = data;

        if (rememberMe) {
          localStorage.setItem('authToken', idToken);
          localStorage.setItem('refreshToken', refreshToken);
        } else {
          sessionStorage.setItem('authToken', idToken);
          sessionStorage.setItem('refreshToken', refreshToken);
        }

        dispatch(login({ token: idToken, email: formData.email }));
        setLoginSuccess(true);

        setTimeout(() => {
          closeLogin();
          if (version === '个人版') {
            navigate('/personCRM');
          } else {
            navigate('/companyCRM');
          }
        }, 3000);
      } else {
        // If it’s a registration request, send the verification code first
        setVerificationStage(true);
        await requestVerificationCode();  // Send verification code
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
    }
  };

  const requestVerificationCode = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
      const response = await fetch(`${baseUrl}/auth/send-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to send verification code');
      }
    } catch (error) {
      setError('An error occurred while sending the verification code');
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();

    try {
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

      // Verify the code first
      const response = await fetch(`${baseUrl}/auth/verify-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          code: verificationCode,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const registrationData = { ...formData, company: selectedCompany };

        // Once the verification is successful, proceed with Firebase registration
        const registerResponse = await fetch(`${baseUrl}/auth/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(registrationData),
        });

        if (!registerResponse.ok) {
          throw new Error('Registration failed. Please try again.');
        }

        // Registration success, notify the user
        setIsRegisterSuccess(true);

        setTimeout(() => {
          setIsRegisterSuccess(false);
          setIsLogin(true); // Switch back to login mode
          setVerificationStage(false); // Exit verification stage
        }, 3000);
      } else {
        setError(data.message || 'Invalid verification code');
      }
    } catch (error) {
      console.error("Verification error: ", error.message);
      setError(error.message || 'An error occurred during verification');
    }
  };

  const toggleVersion = () => {
    setVersion((prevVersion) => (prevVersion === '个人版' ? '企业版' : '个人版'));
  };

  const handleCompanySearch = async (query) => {
    setCompanyQuery(query);
    setSearchPerformed(true);
    setCompanyResults([]);
    if (query.trim()) {
      try {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await fetch(`${baseUrl}/company/search?query=${query}`);
        if (response.ok) {
          const data = await response.json();
          setCompanyResults(data);
        }
      } catch (error) {
        console.error('Company search failed:', error);
      }
    }
  };

  const handleCompanySelect = (company) => {
    const { image, description, detail, ...companyWithoutExcludedFields } = company;

    setSelectedCompany(companyWithoutExcludedFields);
    setCompanyQuery(company.name);
    setSearchPerformed(false);
  };

  const switchToRegister = () => {
    setIsLogin(false);
  };

  const switchToLogin = () => {
    setIsLogin(true);
  };

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 backdrop-blur-lg transition-all duration-500 ${animate ? 'opacity-100' : 'opacity-0'}`}>
      <div className={`bg-white shadow-md rounded-lg p-8 max-w-md w-full transform transition-transform duration-500 ${animate ? 'translate-y-0' : 'translate-y-10'}`}>

        {/* 关闭弹窗  */}
        <FaTimes
          className="absolute top-3 right-3 text-gray-400 cursor-pointer hover:text-gray-600"
          onClick={closeLogin}
          size={20}
        />

        {loginSuccess || isRegisterSuccess ? (
          <div className="text-center">
            <FaCheckCircle className="w-16 h-16 text-green mx-auto" />
            <h2 className="text-2xl font-bold text-gray-700 mt-4">{isLogin ? '登录成功!' : '注册成功!'}</h2>
            <p className="text-gray-700 mt-4">{isLogin ? '正在跳转...' : '跳转回登录界面...'}</p>
          </div>
        ) : verificationStage ? (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-700 mt-4">请输入验证码</h2>
            <form onSubmit={handleVerification}>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow text-gray-700 mt-4"
                placeholder="输入验证码"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
              <button
                type="submit"
                className="w-full bg-yellow text-white font-bold py-2 px-4 rounded-lg hover:bg-yellow-600 hover:text-gray-100 transition duration-200 mt-4"
              >
                验证
              </button>
            </form>
            {error && <p className="text-gray-700 text-center mt-4">{error}</p>}
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-bold text-center text-gray-900 mb-6">
              {isLogin ? '登录帐号' : '注册帐号'}
            </h2>

            {/* Slider for switching between 个人版 and 企业版 */}
            <div className="flex justify-center mb-6">
              <button
                className={`px-4 py-2 font-semibold rounded-l-lg ${version === '个人版' ? 'bg-yellow text-white' : 'bg-gray-200 text-gray-700'}`}
                onClick={() => setVersion('个人版')}
              >
                个人版
              </button>
              <button
                className={`px-4 py-2 font-semibold rounded-r-lg ${version === '企业版' ? 'bg-yellow text-white' : 'bg-gray-200 text-gray-700'}`}
                onClick={() => setVersion('企业版')}
              >
                企业版
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 mb-2">
                  邮件地址
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow text-gray-700"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="password" className="block text-gray-700 mb-2">
                  密码
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow text-gray-700"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>

              {!isLogin && (
                <div className="mb-4">
                  <label htmlFor="confirmPassword" className="block text-gray-700 mb-2">
                    确认密码
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow text-gray-700"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              )}

              {version === '企业版' && !isLogin && (
                <div className="mb-4 relative">
                  <label htmlFor="companySearch" className="block text-gray-700 mb-2">
                    公司名称
                  </label>
                  <input
                    type="text"
                    id="companySearch"
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow text-gray-700"
                    placeholder="搜索公司..."
                    value={companyQuery}
                    onChange={(e) => handleCompanySearch(e.target.value)}
                    ref={companySearchRef}
                  />
                  {searchPerformed && companyResults.length > 0 && (
                    <div className="absolute top-full left-0 right-0 bg-white border rounded-md mt-2 z-50 max-h-40 overflow-auto">
                      <SearchResults
                        results={companyResults}
                        onSelect={(company) => handleCompanySelect(company)}
                      />
                    </div>
                  )}
                </div>
              )}

              {isLogin && (
                <div className="mb-4 flex justify-between items-center">
                  <label className="text-sm text-gray-600">
                    <input type="checkbox" className="mr-2" /> 记住我
                  </label>
                  <a href="/forgot-password" className="text-sm text-gray-600 hover:text-blue hover:underline">
                    忘记密码?
                  </a>
                </div>
              )}

              <button
                type="submit"
                className="w-full bg-yellow text-white font-bold py-2 px-4 rounded-lg hover:bg-yellow-600 hover:text-gray-100 transition duration-200"
              >
                {isLogin ? '登录' : '注册'}
              </button>
            </form>

            <p className="text-center text-gray-600 mt-4">
              {isLogin ? (
                <>
                  没有帐户?{' '}
                  <span onClick={switchToRegister} className="text-gray-600 hover:text-blue hover:underline cursor-pointer">
                    点击注册
                  </span>
                </>
              ) : (
                <>
                  已有帐户?{' '}
                  <span onClick={switchToLogin} className="text-gray-600 hover:text-blue hover:underline cursor-pointer">
                    点击登录
                  </span>
                </>
              )}
            </p>

            {error && <p className="text-gray-700 text-center mt-4">{error}</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default LoginComponent;
