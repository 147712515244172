import React, { useState, useEffect } from 'react';
import SidebarNav from '../Components/PCRM/SidebarNav';
import AccountInfo from '../Components/PCRM/AccountInfo';
import BreadcrumbHeader from '../Components/PCRM/BreadcrumbHeader';
import Message from '../Components/PCRM/UserMessage';
import Points from '../Components/PCRM/UserPoints';
import BrowsingHistory from '../Components/PCRM/BrowsingHistory';
import EnterpriseRedirect from '../Components/PCRM/EnterpriseRedirect';

const PersonCRM = () => {
    const [activeTab, setActiveTab] = useState('account');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMobileMenuToggle = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'account':
                return <AccountInfo />;
            case 'messages':
                return <Message />;
            case 'points':
                return <Points />;
            case 'history':
                return <BrowsingHistory />;
            case 'enterpriseRedirect':
                return <EnterpriseRedirect />;
            default:
                return <div>请选择一个功能</div>;
        }
    };

    return (
        <div className="flex md:w-4/5 sm:w-8/9 mx-auto font-sans mt-5">
            {(<SidebarNav onTabChange={setActiveTab} showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} />)}
            <div className="flex-1 flex flex-col">
                <BreadcrumbHeader
                    isMobile={isMobile}
                    handleMobileMenuToggle={handleMobileMenuToggle}
                    activeTab={activeTab}
                />

                {/* 主界面：账户信息或其他内容 */}
                <div className="px-4 mb-8">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default PersonCRM;
