// src/pages/Landing.js

import React, { Suspense, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import FeaturesDisplay from '../Components/Landing/FeaturesDisplay';
import { fetchLandingPageCompanies } from '../Components/Companies/companiesSlice';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { LanguageData } from '../Components/Common/LanguageData';
import Activity from '../Components/Landing/Activity';
import News from '../Components/News/News';
import { setRegion, setIndustry, setPage, fetchCompanies } from '../Components/Companies/companiesSlice';
import FilterComponent from '../Components/Companies/FilterComponent';
const CompanyItem = React.lazy(() => import('../Components/Companies/CompanyItem'));

const industryMapping = {
  'realestate': '地产开发',
  'design': '建筑设计',
  'builder': '房屋建造',
  'material': '建筑材料',
  'decoration': '室内装修',
  'agency': '房产经纪',
  'mortgage': '房屋信贷',
  'law': '法律咨询',
  'accounting': '会计事务',
};

const Landing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  const {
    landingPageCompanies,
    loadingLandingPageCompanies,
    errorLandingPageCompanies,
    cachedLandingPageCompanies
  } = useSelector((state) => state.companies);

  useEffect(() => {
    if (!cachedLandingPageCompanies) {
      dispatch(fetchLandingPageCompanies());
    }
  }, [dispatch, cachedLandingPageCompanies]);

  const { industry } = useParams();

  const filterRef = useRef(null);

  const { companies, loadingCompanies, cachedCompanies, errorCompanies, selectedRegion, selectedIndustry, currentPage, totalPages } = useSelector(state => state.companies);

  const industryName = industryMapping[industry] || '全部';
  const isAllIndustry = industryName === '全部' || !industryName;

  useEffect(() => {
    // 只在未缓存状态下抓取后端数据
    if (!cachedCompanies) {
      dispatch(fetchCompanies({ region: selectedRegion, industry: selectedIndustry }));
    }
  }, [dispatch, selectedRegion, selectedIndustry, cachedCompanies]);

  const handlePageChange = (page) => {
    dispatch(setPage(page));
  };

  useEffect(() => {
    dispatch(setIndustry(industryName));
  }, [industryName, dispatch]);

  useEffect(() => {
    dispatch(fetchCompanies({ region: selectedRegion, industry: selectedIndustry, page: currentPage }));
  }, [selectedRegion, selectedIndustry, currentPage, dispatch]);


  return (
    <div className="bg-gray-white min-h-screen flex flex-col">
      <main>
        <FeaturesDisplay />
        <div className="max-w-6xl mx-auto">
          <div className="my-8" ref={filterRef}>
            <FilterComponent
              selectedRegion={selectedRegion}
              setSelectedRegion={(region) => dispatch(setRegion(region))}
              selectedIndustry={selectedIndustry}
              setSelectedIndustry={(industry) => dispatch(setIndustry(industry))}
            />
          </div>

          <div className="my-8">
            <Suspense fallback={<div>Loading...</div>}>
              <CompanyItem
                className="text-gray-black"
                companies={companies}
                loadingCompanies={loadingCompanies}
                errorCompanies={errorCompanies}
                selectedRegion={selectedRegion}
                selectedIndustry={selectedIndustry}
                currentPage={1}
              />
            </Suspense>
          </div>
          <div className="flex justify-center">
            <button 
            className="flex items-center justify-center px-6 py-2 border border-gray-300 rounded-full text-gray-500 hover:bg-yellow hover:text-gray-700 transition"
            onClick={() => {
              const url = language === "en" ? "/companies" : "/cn/companies";
              navigate(url);
            }}
            >
            {language === "en" ? "View More →" : "查看更多 →"}
            </button>
          </div>
        </div>
        <Activity />
        <News />
        {/* <InfoDisplay /> */}
      </main>
    </div>
  );
};

export default Landing;
