import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.png';
import QRCode from '../../Assets/QRCode.png';
import XiaohongshuIcon from '../../Assets/FooterIcons/xiaohongshu.svg';
import DouyinIcon from '../../Assets/FooterIcons/douyin.svg';
import InstagramIcon from '../../Assets/FooterIcons/instagram.svg';
import LinkedinIcon from '../../Assets/FooterIcons/linkedin.svg';
import FacebookIcon from '../../Assets/FooterIcons/facebook.svg';
import BilibiliIcon from '../../Assets/FooterIcons/bilibili.svg';
import { useSelector, useDispatch } from 'react-redux';
import { LanguageData } from '../Common/LanguageData';

const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

const Footer = () => {
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const industriesData = LanguageData[language].industries; // 获取当前语言的 industries 内容
  const footerData = LanguageData[language].footer; // 获取当前语言的 footer 内容

  return (
    <footer className="pt-4 pb-4 sm:pt-8 font-sans bg-gray-white">
      <div className="container mx-auto px-4">
      <div className="bg-yellow-500 w-full rounded-2xl flex flex-col items-center p-8 md:p-12 gap-4 md:gap-8 mb-12 md:mb-16">
        <p className="text-white items-center text-center font-semibold text-xl md:text-3xl">{footerData.advertise}</p>
        <Link
          to={getLocalizedPath("/company/livecomfy-pty-ltd", language)} 
          className='bg-white text-yellow-500 py-1 px-4 text-xs md:text-sm hover:scale-110 transition transform duration-300'>
          {footerData.register}
        </Link>
      </div>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8 text-gray-800">
          {/* 第一列：联系方式和社交媒体 */}
          <div className="flex flex-col items-center gap-4">
            <h3 className="text-base sm:text-lg text-black font-semibold mb-1 sm:mb-2">{footerData.contact}</h3>
            <p className="flex items-center mb-1 sm:mb-2 text-xs sm:text-sm">
              +61 484411111
            </p>
            <p className="flex text-center items-center mb-1 sm:mb-2 text-xs sm:text-sm">
              547 / Level 5, 15 Moore Street, Canberra ACT, 2601
            </p>
            <p className="flex items-center mb-1 sm:mb-2 text-xs sm:text-sm">
              info@qixin.com.au
            </p>
            <div className="mt-1 sm:mt-3 flex space-x-1 sm:space-x-4">
              <a href="https://www.xiaohongshu.com/user/profile/61227299000000000101e708" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <img src={XiaohongshuIcon} alt="小红书" className="w-6 h-6 sm:w-7 sm:h-7" />
              </a>
              <a href="https://www.bilibili.com/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <img src={BilibiliIcon} alt="Bilibili" className="w-6 h-6 sm:w-7 sm:h-7" />
              </a>
              <a href="https://www.douyin.com/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <img src={DouyinIcon} alt="抖音" className="w-6 h-6 sm:w-7 sm:h-7" />
              </a>
              <a href="https://www.linkedin.com/company/qixin-co-pty-ltd/about/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <img src={LinkedinIcon} alt="LinkedIn" className="w-6 h-6 sm:w-7 sm:h-7" />
              </a>
              <a href="https://www.instagram.com/qixincoptyltd/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <img src={InstagramIcon} alt="Instagram" className="w-6 h-6 sm:w-7 sm:h-7" />
              </a>
              <a href="https://www.facebook.com/people/Qi-Xin/pfbid0sdrugS454wdJaoK2YXTk2AqcnNSAXMLb5Uo8SV1MnfkpS5ztyoBLMmen4jPSPJRil/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <img src={FacebookIcon} alt="Facebook" className="w-6 h-6 sm:w-7 sm:h-7" />
              </a>
            </div>
          </div>

          {/* 第二列：团队 */}
          <div className="flex flex-col items-center">
            <h3 className="text-base sm:text-lg text-black font-semibold mb-1 sm:mb-2">{footerData.team}</h3>
            <Link to="contact" className="w-fit">
              <span className="text-xs sm:text-sm hover:bg-yellow-500 hover:px-4 hover:rounded-lg hover:text-white hover:scale-110 transition transform duration-300 inline-block">
                {footerData.about}
              </span>
            </Link>
          </div>

          {/* 第三列：业务分类 */}
          <div className="flex flex-col items-center">
            <h3 className="text-base sm:text-lg text-black font-semibold mb-1 sm:mb-2">{footerData.categories}</h3>
            <div className="flex flex-col items-center space-y-1 sm:space-y-2">
              {industriesData.slice().map((industry, index) => (
              <Link to={getLocalizedPath(industry.path, language)}
              key={index} className="w-fit">
                <span className="text-xs sm:text-sm px-4 rounded-lg hover:bg-yellow hover:text-white hover:scale-110 transition transform duration-300 inline-block">
                  {industry.name}                
                </span>
              </Link>
              ))}
            </div>
          </div>

          {/* 第四列：客服 */}
          <div className="flex flex-col items-center gap-8">
            <h3 className="text-base sm:text-lg text-black font-semibold mb-1 sm:mb-2">{footerData.service}</h3>
            <img src={QRCode} alt="QRCode" className="h-32 sm:h-40 mb-4 sm:mb-8" />
          </div>
        </div>
      </div>
      <div className="text-center rounded-xl bg-gray-400 p-2 mt-4 sm:mt-8 text-black">
          <p className="text-xs sm:text-sm">© 2024 Qixin.com.au - All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
